import React from "react";
import { NavLink } from "react-router-dom";

const NavBarLink = ({ route }) => {
  return (
    <li className="capitalize ">
      <NavLink
        to={route.path}
        className={({ isActive }) => (isActive && route.path === "/" ? "active-route" : "pending-route")}
      >
        {route.name}
      </NavLink>
    </li>
  );
};

export default NavBarLink;
