import React from 'react';
import NavBar from './nav_bar/nav_bar';
import HeroSection from './hero_section/hero_section';

const HeaderElement = () => {
  return (
    <div>
      <NavBar></NavBar>
      <HeroSection></HeroSection>
    </div>
  );
};

export default HeaderElement;