import React from "react";
import ai_code_image from "../../../assets/stock-images/ai_code.png";
import web_dev_image from "../../../assets/stock-images/web-dev.png";
import mobile_app_dev_image from "../../../assets/stock-images/mobile-app.png";
import desktop_app_image from "../../../assets/stock-images/desktop-app.png";
import WorkIndustry from "../work_industry/work_industry";
import SectionTitleComponent from "../../section_title_component/section_title_component";

const WorkIndustries = () => {
  const industryGroups = [
    [
      { image: ai_code_image, title: "Artificial Intelligence", bgColor: "#D094E5", grow: false },
      { image: web_dev_image, title: "Mobile app development", bgColor: "#A3DCD4", grow: true }
    ],
    [
      { image: mobile_app_dev_image, title: "Web development", bgColor: "#E8B89C", grow: true },
      { image: desktop_app_image, title: "Desktop app development", bgColor: "#8BCBEF", grow: false }
    ]
  ];

  return (
    <div>
      <SectionTitleComponent title="OurWorkIndustries" />
      {industryGroups.map((industryGroup, idx) => (
        <div className="flex flex-wrap gap-8 mt-8">
          {industryGroup.map((industry) => (
            <WorkIndustry key={idx} {...industry} />
          ))}
        </div>
      ))}
    </div>
  );
};

export default WorkIndustries;
