import React, { useState } from "react";
import "keen-slider/keen-slider.min.css";
import "./new_technology.css";
import { useKeenSlider } from "keen-slider/react";
import SectionTitleComponent from "../../section_title_component/section_title_component";
import slide1 from "../../../assets/stock-images/flutter-banner.jpg";
import slide2 from "../../../assets/stock-images/django.jpeg";
import slide3 from "../../../assets/stock-images/flask-api.png";
import slide4 from "../../../assets/stock-images/fastapi.jpg";

const NewTechnology = () => {
  /**
   * Slider config start
   */
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,
      initial: 0,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      }
    },
    [
      (slider) => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 2000);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      }
    ]
  );
  /**
   *  Slider config end
   */
  return (
    <div className="my-14">
      <SectionTitleComponent title="TechnologiesWeWorkWith" />

      {/* Slider code start here */}
      <div className="mt-12 navigation-wrapper">
        <div ref={sliderRef} className="keen-slider rounded-xl">
          <div className=" keen-slider__slide number-slide1">
            <img style={{ objectFit: "cover", width: "100%", height: "100%" }} src={slide1} alt="" />
          </div>
          <div className=" keen-slider__slide number-slide2">
            <img style={{ objectFit: "cover", width: "100%", height: "100%" }} src={slide2} alt="" />
          </div>
          <div className=" keen-slider__slide number-slide3">
            <img style={{ objectFit: "cover", width: "100%", height: "100%" }} src={slide3} alt="" />
          </div>
          <div className=" keen-slider__slide number-slide4">
            <img style={{ objectFit: "cover", width: "100%", height: "100%" }} src={slide4} alt="" />
          </div>
        </div>
        {loaded && instanceRef.current && (
          <>
            <Arrow
              left
              onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
              disabled={currentSlide === 0}
            />

            <Arrow
              onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
              disabled={currentSlide === instanceRef.current.track.details.slides.length - 1}
            />
          </>
        )}
      </div>
      {loaded && instanceRef.current && (
        <div className="dots">
          {[...Array(instanceRef.current.track.details.slides.length).keys()].map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx);
                }}
                className={"dot" + (currentSlide === idx ? " active" : "")}
              ></button>
            );
          })}
        </div>
      )}
    </div>
  );
};

function Arrow(props) {
  const disabled = props.disabled ? " arrow--disabled" : "";
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${props.left ? "arrow--left" : "arrow--right"} ${disabled}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />}
      {!props.left && <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />}
    </svg>
  );
}

export default NewTechnology;
