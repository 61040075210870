import React from 'react';
import WorkIndustries from './work_industries/work_industries';
import NewTechnology from './new_technology/new_technology';

const MainSection = () => {
  return (
    <div>
      <WorkIndustries></WorkIndustries>
      <NewTechnology/>
    </div>
  );
};

export default MainSection;