import "./App.css";
import "./fonts.css";
import HeaderElement from "./components/header/header";
import ellipse_right from "./assets/background-images/ellipse_right.png";
import ellipse_left from "./assets/background-images/ellipse_left.png";
import footer_bg from "./assets/background-images/footer_rectangle.png";
import footer_splines from "./assets/background-images/footer_splines.png";

import MainSection from "./components/main_section/main_section";
import Footer from "./components/footer/footer";

function App() {
  return (
    <>
      <img src={ellipse_right} alt="Description" className="top-right-image" />
      <img src={ellipse_left} alt="Description" className="mid-left-image" />
      <main className="relative flex items-center justify-center z-1">
        <div className="max-w-[100%]  md:max-w-[80%] md:min-w-[1200px]">
          <HeaderElement></HeaderElement>
          <MainSection></MainSection>
        </div>
      </main>

      <footer className="relative w-full min-h-[594px]">
        <div className="flex items-center justify-center w-full">
          <div className="max-w-[100%] md:w-[80%]  md:max-w-[80%] md:min-w-[1200px]">
            <Footer></Footer>
          </div>
        </div>

        <img className="absolute bottom-0 max-h-[594px]" src={footer_bg} alt="" />
        <img className="absolute z-10 top-10 right-44" src={footer_splines} alt="" />
      </footer>
    </>
  );
}

export default App;
