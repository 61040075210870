import React from "react";
// import bio_image from "../../../assets/portfolio-images/bio-1.png";
import ecve_logo from "../../../assets/logos/ecve-logo.png";

const HeroSection = () => {
  return (
    <div className="flex items-center justify-center w-full">
      <div className="hero-section grow grid grid-cols-[repeat(auto-fit,minmax(0,1fr))]">
        <div className="flex items-center col-span-2 ">
          <div className="text-left">
            <h3 className="text-6xl rock-salt-regular dev-text">
              HI , Are you in need of <br />
              *A trustworthy development partner
            </h3>
            <p className="mt-3">
              We're passionate about crafting experiences that are engaging, accessible, and user-centric software.
            </p>
          </div>
        </div>
        <div className="flex justify-end col-span-1">
          <img src={ecve_logo} alt="" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
