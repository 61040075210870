import React from "react";
import ecve_logo_sm from "../../assets/logos/ecve-logo-sm.png";
import { SiFacebook, SiTelegram, SiFigma, SiYoutube } from "react-icons/si";
import { TfiGithub } from "react-icons/tfi";
// import { BsInstagram } from "react-icons/bs";
import { RiTwitterXLine } from "react-icons/ri";
import { FaMapLocationDot } from "react-icons/fa6";
import { MdMarkEmailUnread } from "react-icons/md";
import { LuPhoneCall } from "react-icons/lu";

const Footer = () => {
const contactSubmit = (event) => {
    event.preventDefault();

    const name = document.getElementById('contact-name').value;
    const email = document.getElementById('contact-email').value;
    const content = document.getElementById('contact-message').value;

    const payload = {
      content: `**New Contact Submission:**\n\n**Name:** ${name}\n**Email:** ${email}\n**Message:** ${content}`
    };

    fetch('https://discord.com/api/webhooks/1282940009824915497/5Xyd8TmfWCrcbvDECcBWzrhPC8zvvYKhRd5t6ICKoJTYU2qg8KFk4uNe3PA-nftdVx8b', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
    .then(response => {
      if (response.ok) {
        alert('Message sent successfully!');
      } else {
        alert('Failed to send the message. Please try again.');
      }
    })
    .catch(error => {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    });
  }
  
  return (
    <div className="relative pt-24">
      <div className="relative z-20 flex items-start justify-between text-white">
        <div className="left">
          <div className="flex flex-col">
            <div className="mb-12">
              <img width={116} height={26} src={ecve_logo_sm} alt="" />
            </div>
            <a href="https://fb.com/ecve.team" className="mb-6">
              <SiFacebook className="w-6 h-6 "></SiFacebook>
            </a>
            <a href="https://github.com/ecve" className="mb-6">
              <TfiGithub className="w-6 h-6"></TfiGithub>
            </a>
            {/* <a href="!#" className="mb-6">
              <SiTelegram className="w-6 h-6"></SiTelegram>
            </a> */}
            {/* <a href="!#" className="mb-6">
              <SiFigma className="w-6 h-6"></SiFigma>
            </a> */}
            {/* <a href="!#" className="mb-6">
              <BsInstagram className="w-6 h-6"></BsInstagram>
            </a> */}
            <a href="https://www.youtube.com/@ECVEtech" className="mb-6">
              <SiYoutube className="w-6 h-6"></SiYoutube>
            </a>
            <a href="https://x.com/EcveTech" className="mb-6">
              <RiTwitterXLine className="w-6 h-6"></RiTwitterXLine>
            </a>
          </div>
        </div>
        <div className="middle">
          <div>
            <h3 className="text-3xl font-bold mb-7">Professional Software Development</h3>
            <p>
              High level experience in web design <br /> and development knowledge, <br /> producing quality work.
            </p>
          </div>
          <div className="contact">
            <h3 className="text-3xl font-bold my-7">Want to Reach Us</h3>
            <ul>
              <li className="flex items-center justify-start mb-6">
                <FaMapLocationDot className="w-6 h-6 mr-4"></FaMapLocationDot>
                <p>Rupsa Ghat, Khulna - 9100 Bangladesh</p>
              </li>
              <li className="flex items-center justify-start mb-6">
                <MdMarkEmailUnread className="w-6 h-6 mr-4"></MdMarkEmailUnread>
                <a href="mailto:contact@ecve.tech">contact@ecve.tech</a>
              </li>
              <li className="flex items-center justify-start mb-6">
                <LuPhoneCall className="w-6 h-6 mr-4"></LuPhoneCall>
                <a href="tel:+8801627462617">+8801627462617</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="right">
          <div class="bg-gray-200/60 backdrop-blur-md rounded-xl p-8 shadow-2xl text-gray-600 w-full md:w-[570px]">
            <h2 class="text-2xl font-bold mb-4 text-right">Contact Us</h2>
            <div className="flex flex-col items-start justify-center">
              <input className="w-full p-4 mb-4 rounded-lg" id="contact-name" type="text" placeholder="Name" />
              <input className="w-full p-4 mb-4 rounded-lg" id="contact-email" type="email" placeholder="Email" />
              <textarea
                className="w-full p-4 mb-4 rounded-lg"
                id="contact-message"
                rows={5}
                placeholder="Message"
              ></textarea>
            </div>
            <div className="flex items-center justify-end">
              <button onClick={contactSubmit} className="h-12 px-4 py-2 bg-white rounded-lg w-28">
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
