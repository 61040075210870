import React from "react";

const WorkIndustry = ({ image, title, bgColor, grow }) => {
  return (
    <div
      className={`overflow-hidden rounded-xl min-w-[455px] max-h-[300px] ${grow ? "flex-grow" : ""}`}
      style={{ background: bgColor }}
    >
      <h4 className="my-8 ml-8">{title}</h4>
      <div className="flex justify-end">
        <img className="w-full pl-24" src={image} alt={title + "image"} />
      </div>
    </div>
  );
};

export default WorkIndustry;
