import React from "react";
import "../header.css";
import NavBarLink from "../nav_bar_link/nav_bar_link";


const NavBar = () => {
  const routes = [
    { id: 1, path: "/", name: "work" },
    { id: 2, path: "#", name: "about" },
    { id: 3, path: "#", name: "project" },
    { id: 4, path: "#", name: "notes" },
    { id: 5, path: "#", name: "contact" }
  ];

  return (
    <div className="flex items-center justify-center nav-bar fira-code-regular">
      <ul className="flex justify-between p-4 mt-12" style={{width: "527px"}}>
        {routes.map((route) => (
          <NavBarLink key={route.id} route={route}></NavBarLink>
        ))}
      </ul>
    </div>
  );
};

export default NavBar;
